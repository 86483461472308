<script setup>
import { reactive, ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import LoadingIcon from "@/components/LoadingIcon.vue";
import ChatQuestionTemplateInput from "@/components/ChatQuestionTemplateInput.vue"
import { chatsApi, adminApi } from "@/api";
import { ChatQuestionTemplates, ChatQuestionTemplatesResponse } from "@/store/modules/chat";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const chatId = route.params.chat_id;
const source = reactive([]);
const selected = ref([]);
const rawGroups = reactive({ data: [] });
let temRes = undefined;
const text = ref("");
const chat = reactive({ data: [] });
const isLoading = ref(false);

const chat_question_template_list = reactive({ data: [] });
let aiModels = [];

onMounted(async () => {
  // 認可をチェック
  try {
    const authResp = await chatsApi.check_authorization();
    console.log(authResp)
    if (authResp.message !== "ok") {
      // 認可が"ok"でない場合、エラーページへリダイレクト
      return router.push(`/admin_error`);
    }
  } catch (error) {
    return router.push(`/admin_error`);
    // ここでエラーページにリダイレクトするか、エラーメッセージを表示する処理を追加
  }
  const resp = await chatsApi.getById(chatId);
  chat.data = resp?.data;
  selected.value = resp?.data.groups.map(item => item.group_name);
  setTemplate();
  await fetchAiModels(chat);
  const resp_group = await adminApi.getAllGroups();
  source.push(...resp_group?.data.map((group) => group.group_name));
  rawGroups.data = resp_group.data;
});

watch(text, (newText) => {
  if (selected.value.indexOf(newText) == -1 && newText != "") {
    selected.value.push(newText);
    text.value = "";
  }
});

// watch(
//   () => chat.data.ai_model_id,
//   (new_ai_model_id) => {
//     if (new_ai_model_id == 2) {
//       chat.data.memory_flag = false;
//     }
//   }
// );

// const disabled_memory_switch = computed(() => {
//   console.log(chat.data.ai_model_id);
//   switch (chat.data.ai_model_id) {
//     case 1:
//       return false;
//     case 2:
//       return false;
//     case 3:
//       return false;
//     default:
//       return true;
//   }
// })

const del = (index) => {
  if (text.value.length === 0) {
    selected.value.splice(index, 1);
  }
};

async function setTemplate() {
  await chatsApi.getTemplateById(chatId).then(response => {
    temRes = response;
    response?.data.forEach(ChatQuestionTemplate => {
      chat_question_template_list.data.push(ChatQuestionTemplate);
    });
  }).catch(() => {
    //const first_chat_question_template = new ChatQuestionTemplates(chatId);
    //chat_question_template_list.data.push(first_chat_question_template);
  });
}

const selectedGroupId = () => {
  if (selected.value.includes("ALL")) {
    return rawGroups.data.filter(group => group.group_name == "ALL").map(group => group.id);
  }
  return rawGroups.data.filter(group => selected.value.includes(group.group_name)).map(group => group.id);
};

const handleReturn = () => {
  router.back();
};

const handleUpdate = async (e) => {
  let nullTemp = false;
  chat_question_template_list.data.forEach(ChatQuestionTemplate => {
    if (!(ChatQuestionTemplate.question_title !== "" && ChatQuestionTemplate.question_text !== "")) {
      alert(t('ChatSettingView.alert_not_edit'));
      nullTemp = true;
      return
    }
  });
  e.preventDefault();
  if (!nullTemp) {
    await updateChat();
  }
};

const updateChat = async () => {
  const data = setResData();
  // console.log("chat_data");
  // console.log(data);
  isLoading.value = true;
  try {
    await chatsApi.updateChat(chatId, data);
    await updateTemplates();
  } catch (error) {
    console.log(error);
    isLoading.value = false;
    alert(t('ChatSettingView.chat_setting_err_msg'));
    return;
  }
  isLoading.value = false;
  //location.reload();
  alert(t('ChatSettingView.chat_setting_ok_msg'));
};

const setResData = () => {
  const data = {
    chat_namespace: chat.data.chat_namespace,
    chat_title: chat.data.chat_title,
    system_prompt: chat.data.system_prompt,
    temperature: chat.data.temperature,
    ai_model_id: chat.data.ai_model_id,
    icon_img: chat.data.icon_img,
    memory_flag: chat.data.memory_flag,
    groups: selectedGroupId()
  }
  return data
};

const handleAddQuestionTemplate = () => {
  if (chat_question_template_list.data.length <= 10) {
    chat_question_template_list.data.push(new ChatQuestionTemplates(chatId));
  } else {
    alert(t('ChatSettingView.alert_max_10'));
  }
}

const handleDeleteTemplate = () => {
  chat_question_template_list.data = chat_question_template_list.data.filter(item => !item.on_check)
  console.log(chat_question_template_list.data)
}

async function updateTemplates() {
  if (temRes == undefined && chat_question_template_list.data.length == 0) {
    return
  }
  if (chat_question_template_list.data.length == 0 || (
    chat_question_template_list.data[0].question_title == "" &&
    chat_question_template_list.data[0].question_text == "")
  ) {
    await chatsApi.deleteChatQuestionTemplates(chatId);
    return
  }

  let templates_lsit = []
  chat_question_template_list.data.forEach(template => {
    const template_response = new ChatQuestionTemplatesResponse(
      template.chat_id,
      template.question_title,
      template.question_text
    )
    templates_lsit.push(template_response)
  });
  await chatsApi.updateChatQuestionTemplates(chat_question_template_list.data);
}

async function fetchAiModels(chat) {
  const response = await chatsApi.getAiModels();
  console.log(response.data)
  aiModels = response.data;
  console.log(chat)
  console.log(aiModels)
  // aiModels から chat.ai_model_id と一致するものを探し、見つかればそのIDを設定
  const matchingModel = aiModels.find(model => model.id === chat.data.ai_model_id);
  console.log("matchingModel:" + matchingModel)
  console.log(chat)
  if (matchingModel) {
    chat.data.ai_model_id = matchingModel.id;
  } else if (aiModels.length > 0) {
    // 一致するものがない場合、デフォルトとして最初のモデルのIDを設定（オプション）
    chat.data.ai_model_id = aiModels[0].id;
  }
}
</script>

<template>
  <!-- チャットボット設定画面 -->
  <LoadingIcon :is-loading="isLoading"></LoadingIcon>
  <br>
  <h4>{{ $t('ChatSettingView.h4') }}</h4>
  <br>
  <label for="chat_namespace" class="form-label">
    {{ $t('ChatSettingView.chat_namespace') }} <span>{{ chatId }}</span>
  </label>
  <br>
  <label for="chat_title" class="form-label">{{ $t('ChatSettingView.chat_title') }} </label>
  <div class="input-group mb-3">
    <input type="text" class="form-control shadow-sm" id="chat_title" v-model="chat.data.chat_title">
  </div>
  <label for="system_prompt" class="form-label">{{ $t('ChatSettingView.system_prompt') }} </label>
  <div class="input-group mb-3">
    <textarea class="form-control shadow-sm" id="system_prompt" v-model="chat.data.system_prompt" rows="5"
      cols="50"></textarea>
  </div>
  <label for="temperature" class="form-label">
    {{ $t('ChatSettingView.temperature') }} <span>{{ chat.data.temperature }}</span>
  </label>
  <div class="input-group mb-3">
    <input type="range" class="form-range" v-model.number="chat.data.temperature" min="0" max="1" step="0.01"
      id="temperature">
  </div>
  <label for="roles" class="form-label">{{ $t('ChatSettingView.group') }}</label>
  <div class="mb-3">
    <div class="tags rounded-pill d-flex flex-wrap mb-3 shadow-sm" v-for="(item, index) in selected" :key="index">
      <span class="content">{{ item }}</span>
      <span class="btn-animation del" @click="del(index)">&times;</span>
    </div>

    <select class="form-select shadow-sm" aria-label="Default select example" v-model="text">
      <option selected></option>
      <option v-for="(item, index) in source" :key="index" :value="item">{{ item }}</option>
    </select>
  </div>

  <label class="form-label">{{ $t('ChatSettingView.template_message') }}</label>
  <div class="d-grid gap-2 d-md-flex justify-content-md-end">
    <button class="btn btn-primary d-md-flex justify-content-md-end shadow" @click="handleAddQuestionTemplate"
      style="padding: 0 50px; margin: 10px;">{{ $t('ChatSettingView.add') }}
    </button>
    <button class="btn btn-primary d-md-flex justify-content-md-end shadow" @click="handleDeleteTemplate"
      style="padding: 0 50px; margin: 10px;">{{ $t('ChatSettingView.del') }}
    </button>
  </div>
  <!-- チャットテンプレートテーブル -->
  <table class="table table-hover" id="chat_question_templates">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">{{ $t('ChatSettingView.title') }}</th>
        <th scope="col">{{ $t('ChatSettingView.msg') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(tem, index) in chat_question_template_list.data" :key="index">
        <td><input class="form-check-input " type="checkbox" v-model="tem.on_check" aria-label="..."></td>
        <td>
          <ChatQuestionTemplateInput :text="tem.question_title" @getInput="(value) => tem.question_title = value" />
        </td>
        <td>
          <ChatQuestionTemplateInput :text="tem.question_text" @getInput="(value) => tem.question_text = value" />
        </td>
      </tr>
    </tbody>
  </table>
  <br>
  <br>
  <label for="model" class="form-label">{{ $t('ChatSettingView.model') }} </label>
  <div class="mb-3">
    <select class="form-select shadow-sm" id="model" v-model="chat.data.ai_model_id">
      <option v-for="model in aiModels" :value="model.id" v-bind:key="model.id">{{ model.model_name }}</option>
    </select>
  </div>
  <!-- <br>
  <div class="form-check form-switch">
    <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" v-model="chat.data.memory_flag"
      :disabled="disabled_memory_switch">
    <label class="form-check-label" for="flexSwitchCheckChecked">
      {{ $t('ChatSettingView.memory') }}
    </label>
  </div> -->
  <br>
  <div class="d-grid gap-2 d-md-flex justify-content-md-end">
    <button class="btn btn-primary shadow" type="button" @click="handleUpdate($event)">
      {{ $t('ChatSettingView.update') }}
    </button>
    <button class="btn btn-primary shadow" type="button" @click="handleReturn">
      {{ $t('ChatSettingView.handle_return') }}
    </button>
  </div>
</template>

<style scoped>
label {
  margin-right: 2rem;
}

.settings-item {
  margin: 3rem 0;
}

.settings-field {
  margin: 10px 5px;
  padding: 0 1rem;
}

input {
  width: 40%;
}

.settings {
  width: 70%;
  margin: 0 auto;
}

.settings-buttons {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.settings-buttons button {
  border: none;
  width: 20%;
  border-radius: 0;
}

.btn-animation:hover {
  cursor: pointer;
}

.tags {
  position: relative;
  float: left;
  color: #000000;
  line-height: 28px;
  margin: 0 4px 4px 0;
  padding: 0 22px 0 10px;
}

.tags .content {
  line-height: 28px;
}

.tags .del {
  width: 22px;
  height: 28px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: -1px;
  right: 0;
}

.tags {
  background-color: #e0e0e0;
  border: 1px solid rgb(54, 54, 54);
  cursor: default;
}
</style>
