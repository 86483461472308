const ja = {
    ChatLayout: {
        nav_chat: "チャット",
        resource: "リソース管理",
        upload: "リソース登録",
        history: "履歴",
        setting: "設定",
        connection: "システム連携",
        delete: "削除",
        clear_messages: "クリア",
        back: "戻る",
    },
    NavBar: {
        chats: "チャット一覧",
        admin: "設定",
        settings: "プロフィール",
        logout: "ログアウト",
        saleslists: "リスト一覧",
        salestemplate: "テンプレート",
        usage: "利用状況",
    },
    EditGroupView: {
        h1: "グループ編集：",
        group_name: "グループ名：",
        created_at: "作成日時：",
        modified_at: "最終更新日時：",
        user_group_h4: "グループのユーザー登録",
        username: "ユーザーID",
        fullname: "氏名",
        roles: "権限",
        user_group_h4_2: "登録可能のチャット",
        chat_title: "チャットボット名",
        submit: "更新",
        handleBack: "戻る",
        edit_group_err: "グループの編集は失敗しました！",
    },
    EditUserView: {
        h1: "ユーザー編集：",
        username: "ユーザーID：",
        mail_address: "メールアドレス：",
        full_name: "氏名：",
        password: "パスワード：",
        show: "表示",
        not_show: "非表示",
        roles: "権限：",
        groups: "グループ：",
        created_at: "アカウント登録日時：",
        modified_at: "最終更新日時：",
        submit: "更新",
        handleBack: "戻る",
        change_password: "パスワード初期化",
        password_format_err: "パスワードは最低8文字、大文字、小文字、数字、特殊文字を含む必要があります。",
        role_error: "権限を選択してください。",
        group_error: "ユーザー所属のグループを選択してください。",
    },
    NewGroupView: {
        h1: "グループ追加",
        group_name: "グループ名：",
        user_group_h4: "グループのユーザー登録",   
        username: "ユーザーID",
        fullname: "氏名",
        roles: "権限",
        user_group_h4_2: "グループのチャット登録",
        chat_title: "チャットボット名",
        submit: "保存",
        handleBack: "戻る",
        new_group_err: "グループ登録は失敗しました！\nすでに登録したグループかどうかを確認してください。",
        registered_group: "※すでに登録されたグループ名です。",
    },
    NewUserView: {
        h1: "ユーザー追加",
        username: "ユーザーID：",
        full_name: "氏名：",
        password: "パスワード：",
        show: "表示",
        not_show: "非表示",
        roles: "権限：",
        groups: "グループ：",
        submit: "新規登録",
        handleBack: "戻る",
        password_format_err: "パスワードは最低8文字、大文字、小文字、数字、特殊文字を含む必要があります。",
        role_error: "権限を選択してください。",
        group_error: "ユーザー所属のグループを選択してください。",
    },
    UserListView: {
        user_h4: "ユーザー一覧",
        add_User: "ユーザー追加",
        username: "ユーザーID",
        fullname: "氏名",
        roles: "権限",
        groups: "グループ",
        modified_at: "最終ログイン",
        created_at: "登録日時",
        operation: "操作",
        edit: "編集",
        delete: "削除",
        group_h4: "チャットアクセス権限グループ一覧",
        add_group: "グループ追加",
        group_name: "グループ名",
        update_date_time: "更新日時",
        ip_h4: "アクセス可能のIP一覧",
        ip_list: "IP",
        add_ip: "IP追加",
        modal_title: "警告",
        warning_msg_1: "グループを削除した場合",
        warning_msg_2: "グループに入ったユーザーとチャットはこのグループから解放されます。",
        warning_msg_3: "該当グループしか関連していないチャットは管理者以外のユーザーが閲覧、または編集できません。",
        warning_msg_4: "よろしいでしょうか？",
        user_warning_msg_1: "本当にユーザーを削除しますか？",
        cancel: "キャンセル",
        del_err: "グループの削除は失敗しました。\n存在していないグループです。\n画面をリフレッシュしてまた、操作してください。",
        ip_del_err: "IPの削除は失敗しました。",
        ip_edit_err: "IPアドレスではありません。IPアドレスを入力してください。",
        ip_edit_err2: "このIPアドレスは既に存在しています。",
        ip_save_err: "IPの保存は失敗しました。",
        save: "保存",
    },
    AdminErrorView: {
        h1: "アクセス権限がありません",
        error_message: "システム管理者にアクセス権限をリクエストするか、アクセス権のあるアカウントに切り替えてください。",
        back_button: "戻る",
    },
    ChatResourceUploadView: {
        h1: "リソース登録",
        resource_type: "リソースタイプ",
        file: "ファイル",
        choose_file: "ファイルを選択",
        file_name: "ファイル名",
        tags: "タグ",
        tags_format: "タグ1,タグ2,タグ3",
        upload: "アップロード",
        page_title: "ページタイトル",
        page_name: "ページ名",
        uploadWebResource: "登録",
        no_files_selected: "ファイルが選択されていません。",
        update_ok_msg: "アップロード成功。",
        update_err_msg: "アップロードに失敗しました。",
        response_ok_msg: "登録成功。",
        response_err_msg: "登録に失敗しました。",
    },
    ChatResourceView: {
        h4: "登録済みリソース一覧",
        keyword: "キーワード",
        tags: "タグ",
        total_number_of_characters: "文字数合計：",
        resource_count: "リソース数：",
        filter: "絞り込み",
        resource_name: "リソース名",
        file_type: "ファイル形式",
        created_at: "登録日時",
        status: "ステータス",
        operation: "操作",
        edit: "編集",
        delete: "削除",
        status_zero: "処理待ち",
        status_one: "読込中",
        status_two: "完了",
        status_three: "エラー",
    },
    ChatScreenView: {
        input_msg: "メッセージを入力してください",
        error_messages: "申し訳ありませんが、リクエスト中に問題が発生しました。しばらくしてからもう一度試してください。",
        close: "閉じる",
        template_message: "質問テンプレート",
        link: "外部リンク",
        new_talk: "+ 会話作成",
        his_talk: "会話履歴",
        memory_flag: "会話記憶",
    },
    ChatSettingView: {
        h4: "チャットボット設定",
        chat_namespace: "チャットID：",
        chat_title: "チャットボット名：",
        system_prompt: "システムプロンプト：",
        temperature: "応答の柔軟性：",
        model: "モデル",
        template_message: "選択肢メッセージ：",
        add: "追加",
        del: "削除",
        title: "タイトル",
        msg: "メッセージ内容",
        must: "入力必須",
        group: "閲覧可能のグループ",
        memory: "会話記憶",
        update: "更新",
        handle_return: "戻る",
        alert_max_10: "テンプレート登録は最大10個までです。",
        alert_not_edit: "選択肢メッセージに未記入の質問があります。\n質問を削除しまたは記入してください。",
        chat_setting_err_msg: "チャットの設定は失敗しました。",
        chat_setting_ok_msg: "チャットの設定は完了しました。",
    },
    NewChatView: {
        h3: "チャットボット作成",
        groups: "閲覧可能のグループ",
        chat_title: "チャットボット名：",
        system_prompt: "システムプロンプト：",
        temperature: "応答の柔軟性：",
        group_err: "閲覧できるグループを選択してください。",
        memory: "会話記憶",
        handleCreate: "新規登録",
        handleReturn: "戻る",
    },
    EditPasswordView: {
        h1: "パスワード変更",
        old_password: "元のパスワード：",
        incorrect_password: "パスワードが正しくありません！",
        new_password: "新しいパスワード：",
        new_password_confirmation: "新しいパスワード（確認）：",
        passwords_do_not_match: "パスワードが一致しません！",
        password_format_err: "パスワードは最低8文字、大文字、小文字、数字、特殊文字を含む必要があります。",
        submit: "変更",
        handleBack: "戻る",
    },
    SettingsView: {
        h4: "プロフィール",
        username: "ユーザーID：",
        full_name: "氏名：",
        mail_address: "メールアドレス：",
        not_mail_address: "入力したデータはメールアドレスではありません！",
        otp_on: "ログイン時にメールで２段階認証を行います。",
        otp_warning: "**注意：２段階認証を行う場合はメールアドレスが必須となります。",
        modal_title: "メールアドレス検証",
        modal_body: "認証コード：",
        otp_msg: "メールに送信した２段階認証コードを入力してください。",
        invalid_otp_code_1: "認証コードが正しくありません！",
        invalid_otp_code_2: "２段階認証はメールアドレスの入力が必須です。",
        setting_ok: "設定は完了しました。",
        send_btn: "送信",
        close_btn: "キャンセル",
        change_password: "パスワード変更",
        update: "更新",
        handle_return: "戻る",
        user_plan: "ご契約プラン照会",
    },
    UserPlanView: {
        plan_detail: "月次メッセージ数",
        element: "項目",
        used: "利用量",
        limit: "制限",
        message_count_3: "メッセージ数(GPT-3.5)",
        message_count_4: "メッセージ数(GPT-4)",
        resource_detail: "リソース利用量",
        resource_characters_count: "リソース文字数",
        user_count: "ユーザー数",
        chat_count: "チャットボット数",
        handle_return: "戻る",
    },
    ChatListView: {
        h4: "チャットボット一覧",
        serach_keyword: "キーワード：",
        chat_count: "チャットボット数：",
        handle_create: "新規作成",
        handle_filter: "絞り込み",
        chat_title: "チャットボット名",
        author: "作成日時",
        operation: "操作",
        delete: "削除",
        modal_title: "警告",
        warning_msg_1: "チャットを削除します。",
        warning_msg_2: "よろしいでしょうか？",
        cancel: "キャンセル",
    },
    ChatView: {
        enter_message: "メッセージを入力してください",
        file_name: "ファイル名",
        tags: "タグ",
        source: "【参照元】：",
    },
    ChatEditResourceView: {
        h4: "リソース編集",
        resource_name: "リソース名：",
        file_type: "ファイル形式：",
        tags: "タグ",
        description: "備考：",
        status: "ステータス：",
        created_at: "登録日時：",
        modified_at: "更新日時：",
        resource_text: "テキスト：",
        submit: "更新",
        backToResources: "戻る",
        error_messages: "エラーが発生しました",
        close: "閉じる",
    },
    ChatConnectionView: {
        h4: "外部システム連携",
        messenger: "Messenger",
        messenger_service_name: "接続先サービス：",
        messenger_active_status: "有効状態：",
        messenger_callback_url: "コールバックURL：",
        messenger_verify_token: "認証トークン：",
        messenger_facebook_page_id: "FacebookページID：",
        messenger_access_token: "アクセストークン：",
        submit: "更新",
        hide_token: "非表示",
        show_token: "表示",
        error_messages: "エラーが発生しました",
        error_messages_fbduplicate: "facebookページIDが重複しているため更新できませんでした",
    },
    LoginView: {
        username: "ユーザー名：",
        invalid_username_or_password: "ユーザー名またはパスワードが正しくありません！",
        modal_title: "２段階認証",
        modal_body: "認証コード：",
        otp_msg: "メールに送信した２段階認証コードを入力してください。",
        invalid_otp_code_1: "認証コードが正しくありません！",
        invalid_otp_code_2: "認証回数が越えています。最初からやり直してください。",
        send_btn: "送信",
        close_btn: "キャンセル",
        password: "パスワード：",
        submit: "ログイン",
        ip_modal_title: "IP制限に関する情報",
        ip_err_1: "このPCのIPアドレスはログインできません。",
        ip_err_2: "IP権限管理者に連絡してください。",
    },
    RegisterView: {
        h4: "登録済みリソース一覧",
        search_keyword: "キーワード",
        tags: "タグ",
        total_number_of_characters: "文字数合計：",
        resource_count: "リソース数：",
        filter: "絞り込み",
        resource_name: "リソース名",
        file_type: "ファイル形式",
        created_at: "登録日時",
        status: "ステータス",
        operation: "操作",
        edit: "編集",
        delete: "削除",
    },
    UploadFileView: {
        upload_title: "リソース登録",
        resource_type: "リソースタイプ",
        file: "ファイル",
        file_upload: "ファイルを選択",
        file_name: "ファイル名",
        tags: "タグ",
        tags_format: "タグ1,タグ2,タグ3",
        uploadFile: "アップロード",
        document_title: "ページタイトル",
        uploadWebResource: "登録",
    },
    TagsInput: {
        admin_del_msg: "管理者が全てのチャットが管理できるために管理者ユーザー権限は削除できません。",
    },

    UploadServiceView: {
        // upload_title    : "1. リソース登録",
        resource_type: "リソースタイプ",
        file: "ファイル",
        file_upload: "ファイルを選択",
        // file_name       : "5. ファイル名",
        // tags            : "6. タグ",
        // tags_format     : "7. タグ1,タグ2,タグ3",
        uploadFile: "アップロード",
        // document_title  : "9. ページタイトル",
        uploadWebResource: "登録",
    },

    SalesListsView: {
        h4: "リスト一覧",
        search_keyword: "キーワード：",
        list_count: "リスト数：",
        handle_create: "新規作成",
        list_title: "リスト名",
        author: "作成日時",
        edit: "編集",
        delete: "削除",

        delete_confirm: "選択したリストを削除しますか？",
        OK: "削除する",
        cancel: "キャンセル"
    },

    SalesListsDetailsLayout: {
        h1: "リストタイトル1",
        company_list: "会社一覧",
        csv_import: "CSVインポート",
        company_setting: "リスト設定"
    },

    SalesListsDetailsView: {
        h4: "会社一覧",
        search_keyword: "キーワード:",
        handle_filter: "絞り込み",
        target_flag: "生成開始",
        target: "生成対象",
        regeneration: "再生成",
        company_information: "会社情報取得",
        text_generate: "文章生成",
        text_confirm: "内容確認",
        send_mail: "メール送信",
        data_count: "データ件数：",

        select_all: "全選択/解除",
        handleCreate: "+ 会社追加",
        handle_send: "一括送信",
        refresh: "一覧更新",
        handle_refresh: "一覧更新",

        selected: "対象",
        company_name: "会社名",
        company_url: "URL",

        crawl_status: "会社情報取得",
        generate_status: "文章生成",
        check_status: "文章確認",
        send_status: "メール送信",

        process_pending: "処理待ち",
        processing: "処理中",
        finish: "完了",
        process_fail: "処理失敗",

        generate_pending: "生成待ち",
        generating: "生成中",
        generate_fail: "生成失敗",

        uncheck: "未確認",
        checked: "確認済",

        send_none: "未送信",
        send_pending: "送信待ち",
        send_processing: "送信中",
        send_finish: "送信済",
        send_fail: "送信失敗",
        send_delivered: "送信成功",
        send_open: "開封済",
        send_click: "クリック済",

        operation: "操作",
        edit: "確認・編集",
        delete: "削除",

        delete_confirm: "を削除しますか?",
        OK: "OK",
        cancel: "キャンセル",
        send_mail_modal_title: "通知",
        send_mail_modal_text: "メール送信が予約されました。 ※「文章確認」=「確認済」のレコードのみ送信されます。",

        model_title: "会社編集・確認",
        model_company_name: "会社名：",
        model_company_url: "URL:",
        model_email: "メールアドレス：",
        model_company_detail: "概要：",
        model_mail_text: "文章：",
        model_check_status: "確認済み：",
        model_update: "更新",
    },

    NewSalesListsDetailsView: {
        h3: "会社編集・確認",

        company_name: "会社名：",
        company_url: "URL:",
        email: "メールアドレス：",
        company_detail: "概要：",
        mail_text: "文章：",

        return: "戻る",
        update: "更新",
    },

    NewSalesListsView: {
        h3: "リスト新規作成 ",

        resource_type: "リソースタイプ",
        file: "ファイル",

        title_base: "基本情報",
        title_service: "商品・サービス情報",
        title_template: "テンプレート選択",

        list_name: "リスト名：",
        sender_company_name: "【送信元】 会社名：",
        sender_name: "【送信元】 担当者名：",
        sender_mail_address: "【送信元】 メールアドレス：",
        mail_title: "メールタイトル：",
        to_name: "【送信先】 宛名：",
        bcc: "【送信先】 BCC：",
        file_upload: "*添付ファイル：",
        must_pdf: "*添付ファイルはpdfのみです。",
        not_pdf: "選択したファイルはpdfではありません。",

        service_name: "営業したい商品/サービスの名称",
        service_detail_summary: "営業したい商品/サービスの概要",

        template_name: "テンプレートタイプ",

        additional_prompt: "プロンプト：",
        check_modal_title: "警告",
        check_modal_close: "閉じる",
        check_modal_msg: "は必須項目です。入力してください。",

        return: "戻る",
        next: "次へ",
        finished: "完了",

        import: "ファイル・URLで入力",
        
    },

    EditSalesListsView: {
        h3: "リスト設定",

        resource_type: "リソースタイプ",
        file: "ファイル",

        title_base: "基本情報",
        title_service: "商品・サービス情報",
        title_template: "テンプレート選択",

        list_name: "リスト名：",
        sender_company_name: "【送信元】 会社名：",
        sender_name: "【送信元】 担当者名：",
        sender_mail_address: "【送信元】 メールアドレス：",
        mail_title: "メールタイトル：",
        to_name: "【送信先】 宛名：",
        bcc: "【送信先】 BCC：",

        service_name: "営業したい商品/サービスの名称：",
        service_detail_summary: "営業したい商品/サービスの概要：",

        template_name: "テンプレートタイプ：",

        additional_prompt: "プロンプト：",
        prompt_str: "【プロンプトとは？】<br>" +
            "本項目では、メール文面生成の際、生成AIに伝える制約事項を追記することができます。<br><br>" +
            "【具体例】<br>" +
            "・文字数を指定する: <br> 「600文字以内で作成してください。」<br><br>" +
            "・打ち合わせ予約リンクを挿入する:<br> 「本文の後半に以下の項目を必ず記載してください。<br>" +
            "“ご希望の方は、以下のリンクからご予約いただければと思います。<br>" +
            "◆ 打ち合わせ予約リンク https://～～～ ※1分程度でご予約が完了します”」<br>",

        check_modal_title: "警告",
        check_modal_close: "閉じる",
        check_modal_msg: "は必須項目です。入力してください。",

        return: "戻る",
        next: "次へ",
        update: "更新",

        import: "ファイル・URLで入力",
        delete_title: "削除",
        delete_confirm: "添付ファイルを削除しますか？",
        OK: "削除する",
        cancel: "キャンセル"
    },

    SalesListsImportView: {
        h3: "CSVインポート",
        csv_drag: "CSVファイルをドラック&ドロップ、または",
        input_directly: "または CSVを使用せず直接入力する",

        table_name: "マッピング",

        field_name: "取り込み項目",
        csv_field: "CSVカラム",
        sample_value: "サンプル値",

        company_name: "会社名",
        company_url: "URL",
        company_detail: "会社概要",
        email: "メールアドレス",
        tel: "電話",
        contact_url: "連絡先URL",
        mail_text: "コンテンツの送信",

        upload_file: "インポート",
    },
    sales_templates: {
        h4: "メール文面テンプレート一覧",
        search_keyword: "キーワード：",
        list_count: "リスト数：",
        filter: "絞り込み",
        handle_create: "新規作成",
        list_title: "テンプレート名",
        author: "作成日時",
        edit: "編集",
        delete: "削除",

        delete_confirm: "選択したテンプレートを削除することを確認します?",
        OK: "もちろん",
        cancel: "キャンセル"
    },

    sales_templates_new: {
        h3: "テンプレート新規作成",

        template_name: "テンプレート名",
        template_text: "サンプルメール文面",

        new: "作成",
        cancel: "キャンセル",

        check_modal_title: "警告",
        check_modal_close: "閉じる",
        check_modal_msg: "は必須項目です。入力してください。",
    },

    sales_templates_edit: {
        h3: "テンプレートの編集",

        template_name: "テンプレート名",
        template_text: "サンプルメール文面",

        update: "更新する",
        cancel: "キャンセル",

        check_modal_title: "警告",
        check_modal_close: "閉じる",
        check_modal_msg: "は必須項目です。入力してください。",
    },

    SalesUsageDetailsView: {
        usage_detail: "ご利用状況",
        item: "項目",
        total_number: "合計件数",
        number_of_success: "成功数",
        number_of_failures: "失敗数",
        number_of_articles_generated: "文章生成数：",
        number_of_emails_sent: "メール送信数：",
        amount_detail: "ご利用金額",
        total_amount: "合計金額：",
        detail: "内訳：",
        quantity: "数量",
        unit_price: "単価",
        amount: "金額",
        base_amount: "基本利用料",
        base_amount1: "基本利用料(",
        base_amount2: "件まで含む)",
        append_amount: "追加生成分",
        back_button: "戻る"
    },

    OptionsErrorView: {
        h1: "アクセス権限がありません",
        error_message: "For Salesへのアクセス権限がありません。",
        back_button: "戻る"
    }

}
export default ja;